import React from 'react';
import './HomePage.css'; // Make sure your CSS file is correctly linked

function HomePage() {
    return (
        <div className="home">
            <header className="home-header">
                <h1>Emerging Technology for Innovation and Integration</h1>
                <p>Illuminate. Visualize. Engage.</p>
            </header>
            <section className="mission">
                <h2>Our Mission</h2>
                <p>At Sentrala, we use cutting-edge machine learning techniques to bring powerful, custom solutions to organizations like yours.</p>
            </section>
            <div className="features">
                <div className="feature">
                    <img src="/images/illuminate1.png" alt="Illuminate" />
                    <div>
                        <h3>Illuminate</h3>
                        <p>Illuminate the hidden potential within data. At Sentrala, we harness state-of-the-art machine learning techniques to quantify an organization's reach, impact, and influence. By integrating metrics like size, credibility, and digital presence, we unveil insights that drive strategic decision-making.</p>
                    </div>
                </div>
                <div className="feature">
                    <div>
                        <h3>Visualize</h3>
                        <p>Visualize complexities in a new dimension. Our advanced techniques turn abstract data into tangible understanding, crafted to withstand bias and highlight critical interactions. This visualization empowers stakeholders to see beyond the surface, aligning unseen connections with your strategic goals.</p>
                    </div>
                    <img src="/images/visualize1.png" alt="Visualize" />
                </div>
                <div className="feature">
                    <img src="/images/drone.png" alt="Engage" />
                    <div>
                        <h3>Engage</h3>
                        <p>Engage with precision. Leveraging our deep expertise and insights from U.S. Special Operations, Sentrala offers precise analytical assessments to ensure you achieve measurable outcomes.</p>
                    </div>
                </div>
            </div>
            <footer className="testimonial">
                <p>"I had the pleasure to work with Sentrala on a project measuring the impacts of a humanitarian intervention in Central Asia, and I cannot recommend them highly enough. Their team had a keen grasp of the media and information landscape in our target region, and they were able to collect data and conduct meaningful analysis in near real-time regarding the effects of our work on local sentiments."</p>
                <p>— Isaac Eagan, Nonprofit executive</p>
            </footer>
            <div className="contact">
                <h3>Contact Us</h3>
                <p>info@sentrala.com</p>
            </div>
        </div>
    );
}

export default HomePage;
