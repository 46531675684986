import React from 'react';
import './ContactPage.css'; 

function ContactPage() {
    return (
        <div className="homec">
        <header className="home-contact">
            <h2>Contact Us</h2>
            <p>Email: <a href="mailto:info@sentrala.com">info@sentrala.com</a></p>
            </header>
        </div>
    );
}

export default ContactPage;
