import React from 'react';
import './GwG.css'; // Ensure you have the correct path for the CSS file

function GwG() {
    return (
        <div id="case-study" className="case-study">
            <h2 className="case-study-title">Grow with Google</h2>
            <div className="case-study-content">
                <div className="case-study-left">
                    <p><strong>THE PROBLEM</strong>: The Grow with Google website states that “Now more than ever, Americans need digital skills to land the jobs they want, advance their careers, and grow their businesses.” With this in mind, GwG was looking for ways to better engage with Hispanic job seekers in hard to reach communities within NYC so they could access and utilize the free resources Google provides.</p>
                </div>
                <div className="case-study-right">
                    <p><strong>THE CLIENT</strong>: Grow with Google aims to help communities by providing free training, tools, and expertise with the belief that technology has the power to unlock new opportunities for growth. When those opportunities are available to everyone, communities across the country can achieve their full potential.</p>
                    <p><strong>THE SOLUTION</strong>: Using social network analysis, Sentrala mapped the nonprofit ecosystem in NYC and identified key community leaders as well as organizations that were previously overlooked during the initial community partnership analysis. This assisted in future economic community support planning.</p>
                    <p><strong>THE RESULT</strong>: GwG’s Hispanic Engagement Initiative was successfully completed four months earlier than anticipated. Its marketing budget was reduced, funds re-allocated, and strategic partnerships with key individuals highly connected to the NYC Hispanic community were initiated.</p>
                </div>
            </div>
        </div>
    );
}

export default GwG;

// d