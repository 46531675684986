import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header'; // Import the Header component
import HomePage from './components/HomePage';
// import AboutPage from './components/AboutPage';
import ContactPage from './components/ContactPage';
// import CaseStudiesPage from './components/CaseStudiesPage';
import './App.css'; // Make sure to import your main CSS
// import Dexis from './components/Dexis';
import GwG from './components/GwG';

function App() {
    return (
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          {/* <Route path="/case-studies/dexis" element={<Dexis />} /> */}
          <Route path="/case-studies/gwg" element={<GwG />} />
        </Routes>
      </Router>
    );
  }
  
  export default App;
